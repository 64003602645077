

// export default Orders;
import React, { useState } from 'react';
import { Table, Button, Form, Container, Alert, Pagination } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const Orders = () => {
    const [customerId, setCustomerId] = useState('');
    const [filterType, setFilterType] = useState('All Time');
    const [ordersSummary, setOrdersSummary] = useState(null);
    const [orderDetails, setOrderDetails] = useState([]);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [searchTerm, setSearchTerm] = useState('');
    const [downlineSummary, setDownlineSummary] = useState(false);
    const itemsPerPage = 10;

    const fetchOrderData = () => {
        if (!customerId) {
            setError("Please enter a valid Customer ID");
            setOrdersSummary(null);
            setOrderDetails([]);
            return;
        }
        if (!downlineSummary) {
            axios.get(`/orders-summary/${customerId}?filter_type=${filterType}`)
                .then((response) => {
                    const data = response.data;
                    setOrdersSummary(data);
                    setError(null);
                    if (!data || Object.keys(data).length === 0) {
                        setError("No orders summary available for this Customer ID.");
                    }
                })
                .catch(() => {
                    setError("No orders summary found for this Customer ID.");
                    setOrdersSummary(null);
                });

            // Fetch Order Details
            axios.get(`/order-summary-details/${customerId}?filter_type=${filterType}`)
                .then((response) => {
                    const data = response.data;
                    setOrderDetails(data);
                    setError(null);
                    setCurrentPage(1);
                    if (data.length === 0) {
                        setError("No order details available for this Customer ID.");
                    }
                })
                .catch(() => {
                    setError("No order details found for this Customer ID.");
                    setOrderDetails([]);
                });
        } else {
            // Fetch Downline Summary
            axios.get(`/orders-summary-downline/${customerId}?filter_type=${filterType}`)
                .then((response) => {
                    const data = response.data;
                    setOrdersSummary(data);
                    setError(null);
                    if (!data || Object.keys(data).length === 0) {
                        setError("No downline summary available for this Customer ID.");
                    }
                })
                .catch(() => {
                    setError("No downline summary found for this Customer ID.");
                    setDownlineSummary(null);
                });

            // Fetch Downline Details
            axios.get(`/order-summary-details-downline/${customerId}?filter_type=${filterType}`)
                .then((response) => {
                    const data = response.data;
                    setOrderDetails(data);
                    setError(null);
                    setCurrentPage(1);
                    if (data.length === 0) {
                        setError("No downline details available for this Customer ID.");
                    }
                })
                .catch(() => {
                    setError("No downline details found for this Customer ID.");
                    setOrderDetails([]);
                });
        }
    };

    // Sorting function
    const sortedOrderDetails = [...orderDetails].sort((a, b) => {
        if (sortConfig.key) {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];
            if (sortConfig.key === 'OrderDate') {
                aValue = new Date(aValue);
                bValue = new Date(bValue);
            }
            return sortConfig.direction === 'ascending'
                ? aValue < bValue ? -1 : 1
                : aValue > bValue ? -1 : 1;
        }
        return 0;
    });

    // Filter orderDetails based on the search term
    const filteredOrderDetails = sortedOrderDetails.filter((order) =>
        Object.values(order).some(value =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredOrderDetails.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredOrderDetails.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const renderSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return '';
    };

    return (
        <Container className="mt-5">
            <h2>Orders Summary</h2>
            <Form.Group className="mb-4">
                <Form.Label>Enter Customer ID:</Form.Label>
                <div className="d-flex">
                    <Form.Control
                        type="text"
                        value={customerId}
                        onChange={(e) => setCustomerId(e.target.value)}
                        placeholder="Enter Customer ID"
                    />
                    <Form.Select
                        className="ms-2 me-2"
                        value={filterType}
                        onChange={(e) => setFilterType(e.target.value)}
                    >
                        <option value="All Time">All Time</option>
                        <option value="The Current Month">The Current Month</option>
                        <option value="Last Month">Last Month</option>
                        <option value="Last Quarter">Last Quarter</option>
                        <option value="Year to Date">Year to Date</option>
                    </Form.Select>
                    <Form.Check
                        type="checkbox"
                        label="Downline Orders"
                        checked={downlineSummary}
                        onChange={(e) => setDownlineSummary(e.target.checked)}
                    />
                    <Button variant="dark" className="ms-2" onClick={fetchOrderData}>Search</Button>
                </div>
                {error && <Alert variant="danger" className="mt-2">{error}</Alert>}
            </Form.Group>

            {orderDetails.length > 0 && ordersSummary && (
                <div className="customer-details">
                    <div className="customer-box">
                        <p><strong>Name:</strong> {ordersSummary.CustomerFirstName} {ordersSummary.CustomerLastName}</p>
                    </div>
                    <div className="customer-box">
                        <p><strong>Customer ID:</strong> {customerId}</p>
                    </div>
                </div>
            )}

            {/* Orders Summary Table */}
            {ordersSummary && (
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Total Number of Orders</th>
                            <th>($) Total Amount of All Orders</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{ordersSummary.TotalNumberOfOrders}</td>
                            <td>{parseFloat(ordersSummary.TotalAmountOfAllOrders).toFixed(2)}</td>
                        </tr>
                    </tbody>
                </Table>
            )}

            {/* Search Input for Order Details */}
            {orderDetails.length > 0 && (
                <Form.Group className="mb-4" controlId="searchInput">
                    <Form.Label>Search Table:</Form.Label>
                    <Form.Control
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search by any field"
                    />
                </Form.Group>
            )}

            {/* Order Details Table */}
            {filteredOrderDetails.length > 0 && (
                <>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th onClick={() => handleSort('OrderID')}>Order ID {renderSortIcon('OrderID')}</th>
                                <th onClick={() => handleSort('OrderDate')}>Order Date {renderSortIcon('OrderDate')}</th>
                                {currentItems[0].CustomerID !== undefined && <th onClick={() => handleSort('CustomerID')}>Customer ID {renderSortIcon('CustomerID')}</th>}
                                {currentItems[0].CustomerName !== undefined && <th onClick={() => handleSort('CustomerName')}>Customer Name {renderSortIcon('CustomerName')}</th>}
                                {currentItems[0].Level !== undefined && <th onClick={() => handleSort('Level')}>Level {renderSortIcon('Level')}</th>}
                                {currentItems[0].EnrollerID !== undefined && <th onClick={() => handleSort('EnrollerID')}>Enroller ID {renderSortIcon('EnrollerID')}</th>}
                                {currentItems[0].EnrollerName !== undefined && <th onClick={() => handleSort('EnrollerName')}>Enroller Name {renderSortIcon('EnrollerName')}</th>}
                                <th onClick={() => handleSort('OrderTotalAmount')}>($)Total Amount {renderSortIcon('OrderTotalAmount')}</th>
                                <th onClick={() => handleSort('Items')}>Items {renderSortIcon('Items')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((order, index) => (
                                <tr key={index}>
                                    <td>{order.OrderID}</td>
                                    <td>{new Date(order.OrderDate).toLocaleDateString()}</td>
                                    {order.CustomerID !== undefined && <td>{order.CustomerID}</td>}
                                    {order.CustomerName !== undefined && <td>{order.CustomerName}</td>}
                                    {order.Level !== undefined && <td>{order.Level}</td>}
                                    {order.EnrollerID !== undefined && <td>{order.EnrollerID}</td>}
                                    {order.EnrollerName && <td>{order.EnrollerName}</td>}
                                    <td>{parseFloat(order.OrderTotalAmount).toFixed(2)}</td>
                                    <td>{order.Items}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {/* Pagination Controls */}
                    {totalPages > 1 && (
                        <Pagination className="justify-content-center mt-4">
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                            {Array.from({ length: totalPages }, (_, index) => (
                                <Pagination.Item
                                    key={index + 1}
                                    active={index + 1 === currentPage}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                        </Pagination>
                    )}
                </>
            )}
        </Container>
    );
};

export default Orders;

